import { User } from 'src/store/store';
import type config from 'config';
import { merge } from 'lodash';
import { defaultGeneratedDataLayer } from './default-snowplow';

export const generateDataLayerSearchPage = (user: User, appConfig: typeof config) =>
  merge({}, defaultGeneratedDataLayer(user, appConfig), {
    client: {
      client: {
        experience: 'TV Api',
      },
    },
    page: {
      id: '9now_search',
      name: 'Search for a TV Show',
    },
  });

import { User } from 'src/store/store';
import { merge } from 'lodash';
import type config from 'config';
import { Layout } from '../types/graphQlTypes';
import { defaultGeneratedDataLayer } from './default-snowplow';

export const generateDataLayerHomePage = (user: User, appConfig: typeof config, layout?: Layout) => {
  const brandData = layout?.metadata?.tracking?.brand?.data || null;
  // Error on required fields - https://nine.atlassian.net/wiki/spaces/TECH/pages/9102230422/Brand
  if (brandData.brandName === null)
    throw new Error(`Snowplow data cannot be set, required API brand data is missing. - ${brandData}`);

  return merge({}, defaultGeneratedDataLayer(user, appConfig), {
    brand: brandData,
    page: generatePageTrackingData(layout),
  });
};

const generatePageTrackingData = (layout?: Layout) => {
  const pageData = layout?.metadata?.tracking?.page.data || null;
  // Error on required fields - https://nine.atlassian.net/wiki/spaces/TECH/pages/9102099220/Page+Content
  if (pageData.id === null || pageData.source === null || pageData.name === null)
    throw new Error(`Snowplow data cannot be set, required API page data is missing. - ${pageData}`);

  return {
    ...pageData,
  };
};

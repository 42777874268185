import { SingleCategoryData } from 'common/types/types-tvApi';
import { User } from 'src/store/store';
import type config from 'config';
import { merge } from 'lodash';
import { defaultGeneratedDataLayer } from './default-snowplow';

export const generateDataLayerShowsPage = (
  user: User,
  appConfig: typeof config,
  payload?: SingleCategoryData
) =>
  merge({}, defaultGeneratedDataLayer(user, appConfig), {
    client: {
      client: {
        experience: 'TV Api',
      },
    },
    page: {
      id: '9now_Genre',
      section: payload?.genre?.slug,
      content_name: payload?.meta?.title,
    },
  });

import { v4 as uuid } from 'uuid';
import { SSOState, User } from 'src/store/store';
import type config from 'config';
import { getCookie, getDevice, getOCID, getOS } from '../browser';
import packageJSON from '../../../package.json';

export const defaultGeneratedDataLayer = (user: User, appConfig: typeof config) => {
  const { name: os } = getOS();
  const { type, model, vendor } = getDevice();
  const loggedOutNUID = appConfig ? getCookie(appConfig?.tracking.nuidCookieName) : null;

  return {
    brand: {
      brandName: '9Now',
      groupName: '9Streaming',
    },
    env: appConfig?.env,
    version: '1.0.0',
    client: {
      client: {
        experience: 'Experience Layer',
        build: packageJSON.version,
        squad: '9Now Web',
      },
    },
    device: {
      class: type,
      manufacturer: vendor,
      model: model ?? '',
      os,
      type: 'web',
    },
    page: {
      id: '9now_homepage', // defaulting the snowplow id to the home_page for the time being
      source: '9Video',
      name: '9Now',
      loggedIn: user.type === SSOState.AUTHENTICATED,
      ocid: getOCID() ?? '',
      viewId: uuid(),
    },
    identity: {
      properties: {
        number: 'userid',
        issuer: 'SSO',
        nuid: user.type === SSOState.AUTHENTICATED ? user.decodedToken.nuid : loggedOutNUID,
      },
    },
  };
};
